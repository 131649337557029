<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="800"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <template #footer>
      <a-space>
        <a-button @click="handleCancle"> 取消 </a-button>
        <a-button type="primary" @click="agreeClick">通过</a-button>
        <a-button type="danger" @click="rejectClick"> 驳回 </a-button>
      </a-space>
    </template>
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 8 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 16 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="12" :xs="24">
          <!-- 厂家 -->
          <a-form-item label="手机号:">
            <div class="ud-text-secondary">
              {{ data.phone }}
            </div>
          </a-form-item>
          <!-- 型号 -->
          <a-form-item label="真实姓名:">
            <div class="ud-text-secondary">{{ data.realName }}</div>
          </a-form-item>
          <!-- 管理员身份证(正面) -->
          <a-form-item label="身份证(正面):">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.idcardPic" :width="100" />
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="12" :xs="24">
          <!-- 机柜尺寸 -->
          <a-form-item label="身份证号码:">
            <div class="ud-text-secondary">{{ data.idcard }}</div>
          </a-form-item>
          <a-form-item> </a-form-item>
          <!-- 管理员身份证(反面) -->
          <a-form-item label="身份证(反面):">
            <div class="ud-text-secondary">
              <img class="photo" :src="data.idcardPic2" :width="100" />
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <a-modal
    title="通过原因"
    :width="400"
    :visible="visibleAgree"
    @cancel="handleCancel"
    @ok="agreeOk()"
  >
    <a-row :gutter="16">
      <a-col :md="24" :sm="24" :xs="24" style="height: 100px">
        <a-textarea
          v-model:value="agreeResult"
          placeholder="请输入通过原因"
          :rows="4"
        />
      </a-col>
    </a-row>
  </a-modal>
  <a-modal
    title="驳回原因"
    :width="400"
    :visible="visibleReject"
    @cancel="handleCancel"
    @ok="rejectOk()"
  >
    <a-row :gutter="16">
      <a-col :md="24" :sm="24" :xs="24" style="height: 100px">
        <a-textarea
          v-model:value="rejectResult"
          placeholder="请输入驳回原因"
          :rows="4"
        />
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import * as userRealApi from '@/api/sys/userReal'
export default {
  name: 'realNameDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {
      visibleAgree: false,
      visibleReject: false,
      agreeResult: '',
      rejectResult: ''
    }
  },
  watch: {
    data() {
      this.agreeResult = this.data.result
      this.rejectResult = this.data.result
    }
  },
  mounted() {
    this.states()
  },
  methods: {
    states() {},
    handleCancle() {
      this.$emit('update:visible', false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 通过 对话框
    agreeClick() {
      this.visibleAgree = true
    },
    // 驳回 对话框
    rejectClick() {
      this.visibleReject = true
    },
    // 关闭通过 驳回对话框
    handleCancel() {
      this.visibleAgree = false
      this.visibleReject = false
    },
    // 通过
    agreeOk() {
      console.log(this.data.state)
      this.visibleAgree = false
      const data = { userId: this.data.userId, result: this.agreeResult }
      userRealApi
        .agree(data)
        .then((res) => {
          // this.loading = false
          if (res.code === 0) {
            data.result = this.agreeResult
            // this.agreeResult = ''
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })

        .catch(() => {})
    },
    rejectOk() {
      this.visibleReject = false
      const data = { userId: this.data.userId, result: this.rejectResult }
      userRealApi
        .turnDown(data)
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            data.result = this.rejectResult
            console.log(data.result)
            this.result = ''
            console.log(data.state)
            this.$message.success(res.msg)
            this.updateVisible(false)
            this.$emit('done')
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })

        .catch(() => {})
    }
  }
}
</script>

<style scoped>
</style>
